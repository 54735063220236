import cn from "classnames";
import { ReactElement, useContext, useRef } from "react";
import { AriaRadioProps, useFocusRing, usePress, useRadio, VisuallyHidden } from "react-aria";
import { RadioGroupState } from "react-stately";

import RadioGroupContext from "../../../state/radio-group-context";
import * as styles from "./RadioButton.module.scss";

export enum RadioButtonVariantsName {
  hasIcon = "hasIcon",
}

interface RadioButtonComponentProps extends AriaRadioProps {
  children: string;
  variantName?: RadioButtonVariantsName;
  adoptionClassName?: string;
}

const RadioButton = (props: RadioButtonComponentProps): ReactElement => {
  const { children, variantName, adoptionClassName } = props;
  const state = useContext(RadioGroupContext) as RadioGroupState;
  const ref = useRef(null);
  const { inputProps, isSelected, isDisabled } = useRadio(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing({ within: false });
  const { pressProps } = usePress({});

  return (
    <>
      <label
        {...pressProps}
        className={cn(styles.label, adoptionClassName, {
          [styles[variantName as string]]: variantName,
          [styles.isSelected]: isSelected,
          [styles.isDisabled]: isDisabled,
          [styles.isFocusVisible]: isFocusVisible,
        })}
      >
        <svg className={cn(styles.icon, "icon")} role="img" aria-hidden="true">
          <use xlinkHref={"#icon-white-check"}></use>
        </svg>
        <span>{children}</span>
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>
      </label>
    </>
  );
};

export default RadioButton;

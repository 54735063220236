import cn from "classnames";
import { ReactElement } from "react";
import { useRadioGroup } from "react-aria";
import { RadioGroupProps, RadioGroupState, useRadioGroupState } from "react-stately";

import RadioGroupContext from "../../../state/radio-group-context";
import * as styles from "./RadioGroup.module.scss";

interface RadioGroupComponentProps extends RadioGroupProps {
  children: ReactElement[];
  adoptionClassName?: string;
}

const RadioGroup = (props: RadioGroupComponentProps): ReactElement => {
  const { children, adoptionClassName } = props;
  const state: RadioGroupState = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup(props, state);

  return (
    <fieldset {...radioGroupProps} className={cn(styles.radioGroup, adoptionClassName)}>
      <RadioGroupContext.Provider value={state}>{children}</RadioGroupContext.Provider>
    </fieldset>
  );
};

export default RadioGroup;
